import Instance from "./instance";

export const CreateAdmin = async (payload) => {
  try {
    return await Instance.instanceToken.post("campaign/admin", payload);
  } catch (error) {
    return error;
  }
};

export const GetAdmins = async () => {
  try {
    return await Instance.instanceToken.get(`campaign/getAllAdminUsers`);
  } catch (error) {
    return error;
  }
};

export const DeleteAdminNew = async (id, params) => {
  try {
    return await Instance.instanceToken.delete(`campaign/delete/${id}`, {
      params
    });
  } catch (error) {
    return error;
  }
};

export const EnableDisableAdmin = async (id, enable) => {
  try {
    return await Instance.instanceToken.put(`campaign/enableAdmin/${id}`, {
      enabled: enable
    });
  } catch (error) {
    return error;
  }
};

export const SetupStripe = async () => {
  try {
    return Instance.instanceToken.get('/payments/setupStripe')
  } catch (error) {
    return error;
  }
}

export const DeleteCampaignResources = async () => {
  try {
    return Instance.instanceToken.delete('/campaign/wipe')
  } catch (error) {
    return error;
  }
}