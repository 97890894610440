var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{ref:"formGroup",class:[
    _vm.localValue
      ? _vm.localValue.toString().length > 0
        ? 'is-filled is-focus'
        : ''
      : '',
    _vm.isFocused ? 'is-focus' : '',
    _vm.whiteBG ? 'white-label-bg' : '',
    _vm.isDisabled ? 'is-disabled' : '',
  ],attrs:{"id":_vm.groupId,"label":_vm.label,"label-for":_vm.id,"label-class":"form-label","description":_vm.description,"invalid-feedback":_vm.invalidFeedback,"state":_vm.state}},[_c('b-input-group',[(_vm.isPrepend)?_c('b-input-group-prepend',{ref:"inputPrepend"},[_vm._t("prepend")],2):_vm._e(),(_vm.isTextArea)?_c('b-form-textarea',{ref:_vm.id,attrs:{"id":_vm.id,"type":_vm.type,"required":_vm.isRequired,"placeholder":_vm.isFocused ? _vm.placeholder : '',"state":_vm.state,"rows":_vm.rows,"max-rows":"6","trim":_vm.trim,"form":_vm.form,"disabled":_vm.isDisabled,"value":_vm.value},on:{"input":(e) => _vm.updateInput(e),"blur":(e) => _vm.localChangeFn(e),"focus":(e) => _vm.onFocus(e)}}):_c('b-form-input',{ref:_vm.id,attrs:{"id":_vm.id,"type":_vm.type,"required":_vm.isRequired,"placeholder":_vm.isFocused ? _vm.placeholder : '',"state":_vm.state,"trim":_vm.trim,"form":_vm.form,"disabled":_vm.isDisabled,"value":_vm.value},on:{"input":(e) => _vm.updateInput(e),"blur":(e) => _vm.localChangeFn(e),"focus":(e) => _vm.onFocus(e)}}),(_vm.isAppend)?_c('b-input-group-append',{ref:"inputAppend"},[_vm._t("append")],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }